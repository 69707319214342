import React from "react";
import "./header.css"

export default function Header() {
  return (
    <header>
      <h1 id="title">freeCodeCamp Survey Form</h1>
      <p id="description">
        Thank you for taking the time to help us improve the platform
      </p>
    </header>
  );
}
