import React from 'react'
import Form from './Form'
import './main.css'

export default function Main() {
  return (
    <main>
        <Form />
    </main>
  )
}
